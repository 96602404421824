import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/mainPageSections/projectStyle.js";

import image1 from "assets/img/inxy.png";
import image2 from "assets/img/rea.png";
import image3 from "assets/img/movel.PNG";

const useStyles = makeStyles(styles);

export default function ProjectSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Some of my Projects</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={image1} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Inxy
                <br />
                <small className={classes.smallTitle}>Discord.js bot</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  My attempt to create a personal bot that progressively gain
                  features that will help me do day to day task.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Button
                  justIcon
                  color="transparent"
                  href="https://github.com/inxudianz/inxy"
                  target="_blank"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-github"} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={image2} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                REA
                <br />
                <small className={classes.smallTitle}>iOS App</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Collaboration with my colleagues to create an application that
                  helps newcomer enter the job industry by reviewing their
                  portfolio.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Button
                  justIcon
                  color="transparent"
                  href="https://github.com/inxudianz/REA"
                  target="_blank"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-github"} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={image3} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Movel
                <br />
                <small className={classes.smallTitle}>iOS App</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Exercise application that has been gamified with time limit
                  and goals to help people find new ways to exercise.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Button
                  justIcon
                  color="transparent"
                  href="https://github.com/inxudianz/Movel"
                  target="_blank"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-github"} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
