import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import { Palette, PersonalVideo, Work } from "@material-ui/icons";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/mainPageSections/briefStyle.js";

const useStyles = makeStyles(styles);

export default function BriefSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>World Class Developer</h2>
          <h5 className={classes.description}>
            As one of the lucky people who got the chance to study in Apple
            Developer Academy, it is my duty to become a world class developer
            who can help one another through technology. By creating solutions that helps people through their daily tasks.
            Becoming a world class developer means that I
            have to focus on several aspects namely:
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Technical"
              description="Being up to date with vast tech world and always keep innovating to help others through tech."
              icon={PersonalVideo}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Business"
              description="Understant to have critical mindset for the better world and provide accessible media for people to enjoy."
              icon={Work}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Design"
              description="Be mindful to every little detail I give to each product and make sure that it can be intuitive and modern."
              icon={Palette}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
