import { title } from "assets/jss/material-kit-react.js";

const briefStyle = {
  section: {
    padding: "70px 0",
    textAlign: "center",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    color: "#FFFFFF",
  },
  description: {
    color: "#E6E6E6",
  },
};

export default briefStyle;
